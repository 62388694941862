import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, gutterSpacing } from '../../styles';
import Container from '../Container';

const defaultProps = {
	gap: {...gutterSpacing},
	gridTemplateColumns: {
		xs: '1fr',
		sm: 'repeat(2, 1fr)',
		md: 'repeat(3, 1fr)',
		desktop: 'repeat(4, 1fr)'
	},
	alignItems: 'start',
	justifyItems: 'center'
};

const useClasses = createClasses((theme, {
	disableGutters,
	dense,
	gap = defaultProps.gap,
	gridTemplateColumns = defaultProps.gridTemplateColumns,
	alignItems = defaultProps.alignItems,
	justifyItems = defaultProps.justifyItems
}) => ({
	root: {
		display: 'grid',
		alignItems,
		justifyItems,
		gap: dense ? gap.xs : gap,
		gridTemplateColumns,
		...(!disableGutters && {
			paddingX: theme.config.containerSpacing
		})
	}
}), {
	name: 'RaGridContainer',
	enableSx: true
});

function GridContainer(props) {
	const {
		classes: classesProp,
		className,
		children,
		dense,
		gap = defaultProps.gap,
		gridTemplateColumns = defaultProps.gridTemplateColumns,
		alignItems = defaultProps.alignItems,
		justifyItems = defaultProps.justifyItems,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<Container
			maxWidth={false}
			{...rest}
			className={classes.root}
		>
			{children}
		</Container>
	);
}

GridContainer.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	disableGutters: PropTypes.bool,
	dense: PropTypes.bool,
	gap: PropTypes.object,
	gridTemplateColumns: PropTypes.object,
	alignItems: PropTypes.string,
	justifyItems: PropTypes.string
};

export default React.memo(GridContainer);
